<template>
  <div class="title">
    <div id="scene1" v-on:click="change">
      <div v-bind:class="{show:isActive}">
        <img alt="" src="../assets/dog_silhouette_bg.svg" class="scene1_img6">
        <div class="scene1_img6_border"></div>
      </div>
      <img alt="" src="../assets/dog.png" class="scene1_img5">
      <img alt="" src="../assets/dog.png" class="scene1_img4">
      <img alt="" src="../assets/touchstart.svg" class="scene1_img3">
      <img alt="" src="../assets/logo.png" class="scene1_img2">
      <img alt="" src="../assets/s1.png" class="scene1_img">
    </div>
  </div>
</template>

<style lang="scss" scoped>
#scene1{
  width: 667px;
  height: 375px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  overflow:hidden;
}
.scene1_img{
  width: 667px;
  height: 375px;
}
.scene1_img2{
  width: 560px;
  position: absolute;
  top: 28%;
  left: 7.6%;
  z-index: 1;
  transform : translate(-50%,-50%);
  animation-name: scene1-img2; /*アニメーションの名前*/
  animation-duration: 1.4s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes  scene1-img2 {
  0% { opacity: 1; transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% { transform: matrix3d(0.128, 0, 0, 0, 0, 0.128, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { transform: matrix3d(0.27, 0, 0, 0, 0, 0.27, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% { transform: matrix3d(0.589, 0, 0, 0, 0, 0.589, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { transform: matrix3d(1.032, 0, 0, 0, 0, 1.032, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% { transform: matrix3d(1.155, 0, 0, 0, 0, 1.155, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% { transform: matrix3d(1.496, 0, 0, 0, 0, 1.496, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(1.518, 0, 0, 0, 0, 1.518, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% { transform: matrix3d(1.497, 0, 0, 0, 0, 1.497, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1.439, 0, 0, 0, 0, 1.439, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% { transform: matrix3d(1.279, 0, 0, 0, 0, 1.279, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.105, 0, 0, 0, 0, 1.105, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { transform: matrix3d(1.063, 0, 0, 0, 0, 1.063, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { transform: matrix3d(0.953, 0, 0, 0, 0, 0.953, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { transform: matrix3d(0.941, 0, 0, 0, 0, 0.941, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { transform: matrix3d(0.942, 0, 0, 0, 0, 0.942, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% { transform: matrix3d(0.951, 0, 0, 0, 0, 0.951, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { transform: matrix3d(0.982, 0, 0, 0, 0, 0.982, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% { transform: matrix3d(0.997, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { transform: matrix3d(0.998, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% { transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% { transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { transform: matrix3d(0.998, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% { transform: matrix3d(0.998, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {  opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}
.scene1_img3{
  width: 200px;
  position: absolute;
  top: 83%;
  left: 35%;
  z-index: 1;
  transform : translate(-50%,-50%);
  opacity: 0;
  animation: scene1-img2 1.49s 0.4s 1 forwards, touch 1.49s 3s infinite forwards;
}

@keyframes touch {
  0% { transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% { transform: matrix3d(0.932, 0, 0, 0, 0, 0.941, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { transform: matrix3d(0.945, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% { transform: matrix3d(0.966, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { transform: matrix3d(0.988, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% { transform: matrix3d(0.994, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% { transform: matrix3d(1.012, 0, 0, 0, 0, 1.033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(1.014, 0, 0, 0, 0, 1.033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% { transform: matrix3d(1.021, 0, 0, 0, 0, 1.024, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1.021, 0, 0, 0, 0, 1.019, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% { transform: matrix3d(1.02, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.015, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { transform: matrix3d(1.013, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { transform: matrix3d(1.006, 0, 0, 0, 0, 0.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { transform: matrix3d(1.005, 0, 0, 0, 0, 0.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { transform: matrix3d(0.998, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% { transform: matrix3d(0.997, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { transform: matrix3d(0.996, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% { transform: matrix3d(0.997, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { transform: matrix3d(0.999, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% { transform: matrix3d(1.001, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { transform: matrix3d(1.001, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}


.scene1_img4{
  width: 300px;
  position: absolute;
  top: 200%;
  right: -30%;
  z-index: 1;
  transform : translate(-50%,-50%);
  animation-name: scene1-img4; /*アニメーションの名前*/
  animation-duration: 5s; /*アニメーションにかける時間*/
  animation-delay: 0.8s; /*始まる時間*/
  animation-iteration-count: infinite;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes  scene1-img4 {
  0%{
    top: 200%;
  }
  10%{
    top: 100%;
  }
  14%{
    top: 100%;
  }
  18%{
    top: 90%;
  }
  22%{
    top: 100%;
  }
  26%{
    top: 90%;
  }
  30%{
    top: 100%;
  }
  40%{
    top: 100%;
  }
  50%{
    top: 200%;
  }
}
.scene1_img5{
  width: 300px;
  position: absolute;
  bottom: 80%;
  left: 17%;
  z-index: 1;
  transform : translate(-50%,-50%)rotate(180deg);
  animation-name: scene1-img5; /*アニメーションの名前*/
  animation-duration: 5s; /*アニメーションにかける時間*/
  animation-delay: 1.6s; /*始まる時間*/
  animation-iteration-count: infinite;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes  scene1-img5 {
  0%{
    bottom: 80%;
  }
  50%{
    bottom: 80%;
  }
  60%{
    bottom: 2%;
  }
  64%{
    bottom: 2%;
  }
  68%{
    bottom: -8%;
  }
  72%{
    bottom: 2%;
  }
  76%{
    bottom: -8%;
  }
  80%{
    bottom: 2%;
  }
  84%{
    bottom: 2%;
  }
  94%{
    bottom: 80%;
  }
  100%{
    bottom: 80%;
  }
}

.scene1_img6{
  width: 2700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  opacity: 0;
}
.show .scene1_img6{
  position: absolute;
  opacity: 1;
  animation-name: scene1-img6; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
.show .scene1_img6_border{
  width: 677px;
  height: 375px;
  border: solid 16px #FEA200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img7; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes  scene1-img6 {
  0%{
    width: 2708px;
    height: 1500px;
  }
  10%{
    width: 2708px;
    height: 1500px;
  }
  40%{
    width: 677px;
    height: 375px;
  }
  46%{
    width: 677px;
    height: 375px;
  }
  52%{
    width: 1015.5px;
    height: 562.5px;
  }
  57%{
    width: 1015.5px;
    height: 562.5px;
  }
  90%{
    width: 0px;
    height: 0px;
  }
}
@keyframes  scene1-img7 {
  0%{
    width: 2610px;
    height: 1402px;
    border: solid 98px #FEA200;
  }
  10%{
    width: 2610px;
    height: 1402px;
    border: solid 98px #FEA200;
  }
  40%{
    width: 677px;
    height: 375px;
  }
  46%{
    width: 677px;
    height: 375px;
  }
  52%{
    width: 1015.5px;
    height: 562.5px;
  }
  57%{
    width: 1015.5px;
    height: 562.5px;
  }
  86%{
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
  100%{
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
}
</style>

<script>
export default {
  //name: 'HelloWorld',
  //data () {
    //return {
      //msg: 'Welcome to Your Vue.js App',
      //text: 'Test data',
      //showText: false
    //}
  //},//こっから下追加！！！！！！！！
  data () {
    return {
      isActive: false,
    }
  },//こっから下追加！！！！！！！！
  methods: {
    //title: function () {
      //this.show = !this.show
      //this.show = !this.show
      //alert("I was clicked!");
      //audio.src="@/assets/dog.mp3";
      //https://on-jin.com/
      //audio.play(); //audioを再生
      //ここが上手くできない
    //}
    change:function(){
      this.isActive = !this.isActive;
      setTimeout(this.closeModal, 3000);
    },
    closeModal:function() {
      //親コンポーネント側でモーダルを消す処理
      window.location.href = '1';
    }
  }
}
/*var alertmsg = function(){
  var list_element = document.getElementById("loading");
  list_element.remove();
}
setTimeout(alertmsg, 4000);*/
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
/*var clickMe = document.getElementsByClassName("title");
  clickMe.onclick = function() { alert("I was clicked!"); }; //こういうふうにイベントリスナーを設定してあると
  clickMe.click();*/
/*export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}*/
</script>
