<template>
  <div class="about">

    <!--loading開始アニメーション-->
    <div id="scene2">
      <img alt="" src="../assets/dog_silhouette_bg.svg" class="scene1_img6">
      <div class="scene1_img6_border"></div>
    </div>

    <!--loading終了アニメーション-->
    <div id="scene3" v-bind:class="{show:isActive2}">
      <img alt="" src="../assets/dog_silhouette_bg.svg" class="scene1_img8">
      <div class="scene1_img8_border"></div>
    </div>

    <!--犬と椅子と机と背景-->
    <img alt="" src="../assets/q2.png" class="scene3a_img">
    <img alt="" src="../assets/dog.svg" class="scene3c_img" v-bind:class="{show:judgment == 0}">
    <img alt="" src="../assets/dog2.svg" class="scene3c_img" v-bind:class="{show:judgment == 1}">
    <img alt="" src="../assets/dog3.svg" class="scene3c_img" v-bind:class="{show:judgment == 2}">
    <img alt="" src="../assets/q1.png" class="scene3b_img">

    <!--問題 1-->
    <div id="select" v-bind:class="{show:quiz1}">
      <div class="right_select" v-on:click="increment1">
        <img src="../assets/quizua/select04.png">
      </div>
      <div class="left_select" v-on:click="decrement1">
        <img src="../assets/quizub/select01.png">
      </div>
      <img src="../assets/seikai.png" class="seikai_text" v-bind:class="{show:judgment2 == 1}">
      <img src="../assets/fuseikai.png" class="fuseikai_text" v-bind:class="{show:judgment2 == 2}">
    </div>

    <!--問題 2-->
    <div id="select" v-bind:class="{show:quiz2}">
      <div class="right_select2" v-on:click="decrement2">
        <img src="../assets/quizub/select02.png">
      </div>
      <div class="left_select2" v-on:click="increment2">
        <img src="../assets/quizua/select08.png">
      </div>
      <img src="../assets/seikai.png" class="seikai_text" v-bind:class="{show:judgment2 == 1}">
      <img src="../assets/fuseikai.png" class="fuseikai_text" v-bind:class="{show:judgment2 == 2}">
    </div>

    <!--問題 3-->
    <div id="select" v-bind:class="{show:quiz3}">
      <div class="right_select2" v-on:click="increment3">
        <img src="../assets/quizua/select09.png">
      </div>
      <div class="left_select2" v-on:click="decrement3">
        <img src="../assets/quizub/select03.png">
      </div>
      <img src="../assets/seikai.png" class="seikai_text" v-bind:class="{show:judgment2 == 1}">
      <img src="../assets/fuseikai.png" class="fuseikai_text" v-bind:class="{show:judgment2 == 2}">
    </div>

    <!--問題 4-->
    <div id="select" v-bind:class="{show:quiz4}">
      <div class="right_select2" v-on:click="increment4">
        <img src="../assets/quizua/select10.png">
      </div>
      <div class="left_select2" v-on:click="decrement4">
        <img src="../assets/quizub/select05.png">
      </div>
      <img src="../assets/seikai.png" class="seikai_text" v-bind:class="{show:judgment2 == 1}">
      <img src="../assets/fuseikai.png" class="fuseikai_text" v-bind:class="{show:judgment2 == 2}">
    </div>

    <!--問題 5-->
    <div id="select" v-bind:class="{show:quiz5}">
      <div class="right_select2" v-on:click="decrement5">
        <img src="../assets/quizub/select06.png">
      </div>
      <div class="left_select2" v-on:click="increment5">
        <img src="../assets/quizua/select08.png">
      </div>
      <img src="../assets/seikai.png" class="seikai_text" v-bind:class="{show:judgment2 == 1}">
      <img src="../assets/fuseikai.png" class="fuseikai_text" v-bind:class="{show:judgment2 == 2}">
    </div>

    <!--結果発表-->
    <div class="result" v-bind:class="{show:quiz6}">
      <div class="asobikata">
        <p>きろく</p>
      </div>
      <!--問題回答数-->
      <!--<p class="count">{{ count }}</p>
      <div class="count_solid"></div>-->
      <p class="countA">せいかい：{{ countA }}</p>
      <div class="start" v-on:click="change2">
        <p>もういちど</p>
      </div>
    </div>

    <img src="../assets/youi.png" class="youi_text">
    <img src="../assets/sutart.png" class="start_text">



  </div>
</template>
<script>
export default {
  data () {
    return {
      //クイズ番号
      quiz1: true,
      quiz2: false,
      quiz3: false,
      quiz4: false,
      quiz5: false,
      //回答数
      count: 0,
      //正答数
      countA: 0,
      //正答不正解
      judgment: 0,
      judgment2: 0,
      //ページ遷移
      isActive2: false,
    }
  },
  methods: {
    change2:function(){
      this.isActive2 = !this.isActive2;
      setTimeout(this.closeModal, 3000);
    },
    closeModal:function() {
      //親コンポーネント側でモーダルを消す処理
      window.location.href = '1';
    },

    //回答1
    increment1() { //正解
      this.count++;//解いた問題数プラス
      this.countA++;//正解の問題数
      this.quiz1 = !this.quiz1;//問題1を非表示に
      this.quiz2 = !this.quiz2;//問題2を表示に
      this.judgment = 2;
      this.judgment2 = 2;
    },
    decrement1() { //不正解
      this.count++;//解いた問題数プラス
      this.quiz1 = !this.quiz1;//問題1を非表示に
      this.quiz2 = !this.quiz2;//問題2を表示に
      this.judgment = 1;
      this.judgment2 = 1;
    },

    //回答2
    increment2() { //正解
      this.count++;//解いた問題数プラス
      this.countA++;//正解の問題数
      this.quiz2 = !this.quiz2;//問題2を非表示に
      this.quiz3 = !this.quiz3;//問題3を表示に
      this.judgment = 2;
      this.judgment2 = 2;
    },
    decrement2() { //不正解
      this.count++;//解いた問題数プラス
      this.quiz2 = !this.quiz2;//問題2を非表示に
      this.quiz3 = !this.quiz3;//問題3を表示に
      this.judgment = 1;
this.judgment2 = 1;
    },

    //回答3
    increment3() { //正解
      this.count++;//解いた問題数プラス
      this.countA++;//正解の問題数
      this.quiz3 = !this.quiz3;//問題3を非表示に
      this.quiz4 = !this.quiz4;//問題4を表示に
      this.judgment = 2;
      this.judgment2 = 2;
    },
    decrement3() { //不正解
      this.count++;//解いた問題数プラス
      this.quiz3 = !this.quiz3;//問題3を非表示に
      this.quiz4 = !this.quiz4;//問題4を表示に
      this.judgment = 1;
this.judgment2 = 1;
    },

    //回答4
    increment4() { //正解
      this.count++;//解いた問題数プラス
      this.countA++;//正解の問題数
      this.quiz4 = !this.quiz4;//問題4を非表示に
      this.quiz5 = !this.quiz5;//問題5を表示に
      this.judgment = 2;
      this.judgment2 = 2;
    },
    decrement4() { //不正解
      this.count++;//解いた問題数プラス
      this.quiz4 = !this.quiz4;//問題4を非表示に
      this.quiz5 = !this.quiz5;//問題5を表示に
      this.judgment = 1;
this.judgment2 = 1;
    },

    //回答5
    increment5() { //正解
      this.count++;//解いた問題数プラス
      this.countA++;//正解の問題数
      this.quiz5 = !this.quiz5;//問題5を非表示に
      this.quiz6 = !this.quiz6;//問題6を表示に
      this.judgment = 2;
      this.judgment2 = 2;
    },
    decrement5() { //不正解
      this.count++;//解いた問題数プラス
      this.quiz5 = !this.quiz5;//問題5を非表示に
      this.quiz6 = !this.quiz6;//問題6を表示に
      this.judgment = 1;
this.judgment2 = 1;
    },
  },
  computed: {
      evenOrOdd() {
        return this.count % 2 === 0 ? 'even' : 'odd'
      }
  },
}
</script>
<style lang="scss" scoped>
#select{
  display: none;
  width: 667px;
  height: 375px;
  overflow: hidden;
}
#select.show{
  display: inline-block
}
.right_select{
  position: absolute;
  right: -180px;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 10;
  animation-name: right; /*アニメーションの名前*/
  animation-duration: 0.8s; /*アニメーションにかける時間*/
  animation-delay: 5s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes right {
  0%{
    right: -180px;
  }
  100%{
    right: 50px;
  }
}
.left_select{
  position: absolute;
  left: -180px;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 10;
  animation-name: left; /*アニメーションの名前*/
  animation-duration: 0.8s; /*アニメーションにかける時間*/
  animation-delay: 5s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes left {
  0%{
    left: -180px;
  }
  100%{
    left: 50px;
  }
}
.right_select2{
  position: absolute;
  right: -180px;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 10;
  animation-name: right; /*アニメーションの名前*/
  animation-duration: 0.8s; /*アニメーションにかける時間*/
  animation-delay: 1s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes right {
  0%{
    right: -180px;
  }
  100%{
    right: 50px;
  }
}
.left_select2{
  position: absolute;
  left: -180px;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 10;
  animation-name: left; /*アニメーションの名前*/
  animation-duration: 0.8s; /*アニメーションにかける時間*/
  animation-delay: 1s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes left {
  0%{
    left: -180px;
  }
  100%{
    left: 50px;
  }
}
.aa{
  position: fixed;
  top: 0;
  right: 0;
}
#scene2{
  width: 667px;
  height: 375px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  overflow:hidden;
  animation-name: scene1-img6; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes  scene1-img6 {
  0%{
    width: 667px;
    height: 375px;
  }
  98%{
    width: 667px;
    height: 375px;
    opacity: 1;
  }
  99%{
   display: none!important;
    width: 0;
    height: 0;
    border: none!important;
    opacity: 0;
  }
  100%{
    display: none!important;
    width: 0;
    height: 0;
    border: none!important;
    opacity: 0;
  }
}
.scene3a_img{
  width: 667px;
  height: 375px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow:hidden;
}
.scene3b_img{
  width: 667px;
  height: 375px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  overflow:hidden;
}
.scene3c_img{
  display: none;
  height: 375px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 8;
  overflow:hidden;
}
.scene3c_img.show{
  display: inline;
}



//きろく表示
.result{
  display: none;
  width: 620px;
  height: 310px;
  background: #ffffff98;
  border: #FD9802 solid 5.4px;
  border-radius: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 20;
}
.result.show{
  display: inline;
}
//きろく
.asobikata{
  background: #FD9802;
  border: #FD9802 solid 7px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 30px;
  z-index: 21;
}
.asobikata p{
  font-size: 18px;
  font-weight: 900;
  color: #FFFFFF;
  padding: 4px 14px;
  margin: 0;
}
//もう一度
.start{
  background: #ffffff;
  border: #FD9802 solid 5px;
  border-radius: 10px;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 21;
}
.start p{
  font-size: 16px;
  font-weight: 900;
  color: #FD9802;
  padding: 10px 20px;
  margin: 0;
}
//正解数
.countA{
  white-space: nowrap;
  margin: 0;
  width: auto;
  font-size:40px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}



.start_text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img12; /*アニメーションの名前*/
  animation-duration: 1.5s; /*アニメーションにかける時間*/
  animation-delay: 4s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes  scene1-img12 {
  0%{
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    display: none;
  }
}

.youi_text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img10; /*アニメーションの名前*/
  animation-duration: 1.5s; /*アニメーションにかける時間*/
  animation-delay: 3s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes  scene1-img10 {
  0%{
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    display: none;
  }
}


.youi_text{
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img11; /*アニメーションの名前*/
  animation-duration: 2s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes  scene1-img11 {
  0%{
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    display: none;
  }
}



.seikai_text{
  display: none;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img11; /*アニメーションの名前*/
  animation-duration: 1s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes  scene1-img11 {
  0%{
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    display: none;
  }
}
.seikai_text.show{
  display: inline;
}
.fuseikai_text{
  display: none;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img11; /*アニメーションの名前*/
  animation-duration: 1s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes  scene1-img11 {
  0%{
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    display: none;
  }
}
.fuseikai_text.show{
  display: inline;
}



.scene1_img6{
  display: hidden;
  width: 2700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  opacity: 0;
}
.scene1_img6{
  position: absolute;
  opacity: 1;
  animation-name: scene1-img5; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
.scene1_img6_border{
  display: hidden;
  width: 677px;
  height: 375px;
  border: solid 16px #FEA200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img4; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes  scene1-img5 {
  0%{
    display: inline;
    width: 0px;
    height: 0px;
  }
  10%{
    width: 0px;
    height: 0px;
  }
  43%{
    width: 1015.5px;
    height: 562.5px;
  }
  48%{
    width: 1015.5px;
    height: 562.5px;
  }
  54%{
    width: 677px;
    height: 375px;
  }
  60%{
    width: 677px;
    height: 375px;
  }
  90%{
    width: 2708px;
    height: 1500px;
  }
  99%{
    width: 2708px;
    height: 1500px;
    opacity: 1;
  }
  100%{
    display: none!important;
    width: 0;
    height: 0;
    border: none!important;
    opacity: 0;
  }
}
@keyframes  scene1-img4 {
  0%{
    display: inline;
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
  14%{
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
  43%{
    width: 1015.5px;
    height: 562.5px;
  }
  48%{
    width: 1015.5px;
    height: 562.5px;
  }
  54%{
    width: 677px;
    height: 375px;
  }
  60%{
    width: 677px;
    height: 375px;
  }
  90%{
    width: 2610px;
    height: 1402px;
    border: solid 98px #FEA200;
  }
  99%{
    width: 2610px;
    height: 1402px;
    border: solid 98px #FEA200;
    opacity: 1;
  }
  100%{
    display: none!important;
    width: 0;
    height: 0;
    border: none!important;
    opacity: 0;
  }
}







/*終了アニメーション*/
#scene3{
  display: none;
  width: 667px;
  height: 375px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -30;
  overflow:hidden;
}
#scene3.show{
  display: inline;
  width: 667px;
  height: 375px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -30;
  overflow:hidden;
}
.scene1_img8{
  display: none;
  width: 2700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  opacity: 0;
}
.show.scene1_img8{
  display: block;
  position: absolute;
  opacity: 1;
  animation-name: scene1-img8; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
.scene1_img8_border{
  display: none;
  width: 677px;
  height: 375px;
  border: solid 16px #FEA200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img9; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
.show.scene1_img8_border{
  display: hidden;
  width: 677px;
  height: 375px;
  border: solid 16px #FEA200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img9; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes  scene1-img8 {
  0%{
    display: block;
    width: 2708px;
    height: 1500px;
  }
  40%{
    width: 677px;
    height: 375px;
  }
  46%{
    width: 677px;
    height: 375px;
  }
  52%{
    width: 1015.5px;
    height: 562.5px;
  }
  57%{
    width: 1015.5px;
    height: 562.5px;
  }
  90%{
    width: 0px;
    height: 0px;
  }
}
@keyframes  scene1-img9 {
  0%{
    display: block;
    width: 2610px;
    height: 1402px;
    border: solid 98px #FEA200;
  }
  40%{
    width: 677px;
    height: 375px;
  }
  46%{
    width: 677px;
    height: 375px;
  }
  52%{
    width: 1015.5px;
    height: 562.5px;
  }
  57%{
    width: 1015.5px;
    height: 562.5px;
  }
  86%{
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
  100%{
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
}
</style>
