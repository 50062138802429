<!--<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view/>
</template>-->
<template>
  <!--<router-link to="/">0.タイトル画面</router-link> |
  <router-link to="/1">1.ローディング→ストーリー</router-link> |
  <router-link to="/2">2.あそびかた→ゲームスタート！</router-link>
  <counter></counter>-->
  <!-- ▼ ゲーム画面の領域 ▼ -->
  <div id="view">
    <transition name="bounce">
      <router-view />
    </transition>
  </div>
</template>

<!--<script>
import Counter from './components/Counter.vue'

export default {
  name: 'HelloWorld',
  components: {
    Counter
  }
}
</script>-->
<style lang="scss">



/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}*/
#view{
  width: 667px;
  height: 375px;
  background: #FEA200;
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
/*.bounce-enter { transform: translate(-100px, 0); opacity: 0; } 
.bounce-enter-to { opacity: 1; } 
.bounce-enter-active { transition: all 1s 0s ease; } 
.bounce-leave { transform: translate(0, 0); opacity: 1; } 
.bounce-leave-to { transform: translate(100px, 0); opacity: 0; }
.bounce-leave-active { transition: all .5s 0s ease; }
/*.bounce-enter-active {
  animation: bounce-in 3s;
}
.bounce-leave-active {
  animation: bounce-in 3s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}*/
</style>
