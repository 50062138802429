import { createRouter, createWebHistory } from 'vue-router'
import scene1 from '../views/scene1.vue'
import scene2 from '../views/scene2.vue'
import scene3 from '../views/scene3.vue'
import 'normalize.css'

const routes = [
  {
    path: '/',
    name: 'scene1',
    component: scene1
  },
  {
    path: '/1',
    name: 'scene2',
    component: scene2
  },
  {
    path: '/2',
    name: 'scene3',
    component: scene3
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router