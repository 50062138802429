<template>
  <div class="about">
    <div id="scene2">
<!--loading-->
      <div class="scene1_img6_border"></div>
      <img alt="" src="../assets/dog_silhouette_bg.svg" class="scene1_img6">


      <div class="asobikata">
        <p>あそびかた</p>
      </div>
      <div class="start" v-on:click="change2">
        <p>ゲームをはじめる ></p>
      </div>
      <div class="rule">
        <img src="../assets/asobikata.png" class="asobikata_img">
      </div>


      <!--loading-->
      <div v-bind:class="{show:isActive2}">
        <img alt="" src="../assets/dog_silhouette_bg.svg" class="scene1_img8">
        <div class="scene1_img8_border"></div>
      </div>
    </div>
    <img alt="" src="../assets/s2.png" class="scene2_img">
  </div>
</template>
<script>
export default {
  //name: 'HelloWorld',
  //data () {
    //return {
      //msg: 'Welcome to Your Vue.js App',
      //text: 'Test data',
      //showText: false
    //}
  //},//こっから下追加！！！！！！！！
  data () {
    return {
      isActive2: false,
    }
  },//こっから下追加！！！！！！！！
  methods: {
    //title: function () {
      //this.show = !this.show
      //this.show = !this.show
      //alert("I was clicked!");
      //audio.src="@/assets/dog.mp3";
      //https://on-jin.com/
      //audio.play(); //audioを再生
      //ここが上手くできない
    //}
    change2:function(){
      this.isActive2 = !this.isActive2;
      setTimeout(this.closeModal, 3000);
    },
    closeModal:function() {
      //親コンポーネント側でモーダルを消す処理
      window.location.href = '2';
    }
  }
}
/*var alertmsg = function(){
  var list_element = document.getElementById("loading");
  list_element.remove();
}
setTimeout(alertmsg, 4000);*/
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
/*var clickMe = document.getElementsByClassName("title");
  clickMe.onclick = function() { alert("I was clicked!"); }; //こういうふうにイベントリスナーを設定してあると
  clickMe.click();*/
/*export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}*/
</script>
<style lang="scss" scoped>
#scene2{
  width: 667px;
  height: 375px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  overflow:hidden;
}
.scene2_img{
  width: 667px;
  height: 375px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow:hidden;
}
.scene1_img6{
  display: hidden;
  width: 2700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  opacity: 0;
}
.scene1_img6{
  position: absolute;
  opacity: 1;
  animation-name: scene1-img6; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
.scene1_img6_border{
  display: hidden;
  width: 677px;
  height: 375px;
  border: solid 16px #FEA200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img7; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes  scene1-img6 {
  0%{
    display: inline;
    width: 0px;
    height: 0px;
  }
  10%{
    width: 0px;
    height: 0px;
  }
  43%{
    width: 1015.5px;
    height: 562.5px;
  }
  48%{
    width: 1015.5px;
    height: 562.5px;
  }
  54%{
    width: 677px;
    height: 375px;
  }
  60%{
    width: 677px;
    height: 375px;
  }
  90%{
    width: 2708px;
    height: 1500px;
  }
  99%{
    width: 2708px;
    height: 1500px;
    opacity: 1;
  }
  100%{
    display: none!important;
    width: 0;
    height: 0;
    border: none!important;
    opacity: 0;
  }
}
@keyframes  scene1-img7 {
  0%{
    display: inline;
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
  14%{
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
  43%{
    width: 1015.5px;
    height: 562.5px;
  }
  48%{
    width: 1015.5px;
    height: 562.5px;
  }
  54%{
    width: 677px;
    height: 375px;
  }
  60%{
    width: 677px;
    height: 375px;
  }
  90%{
    width: 2610px;
    height: 1402px;
    border: solid 98px #FEA200;
  }
  99%{
    width: 2610px;
    height: 1402px;
    border: solid 98px #FEA200;
    opacity: 1;
  }
  100%{
    display: none!important;
    width: 0;
    height: 0;
    border: none!important;
    opacity: 0;
  }
}
.none{
  display: none;
}
.rule{
  width: 620px;
  height: 310px;
  background: #ffffff98;
  border: #FD9802 solid 5.4px;
  border-radius: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 20;
}
.asobikata{
  background: #FD9802;
  border: #FD9802 solid 7px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 30px;
  z-index: 21;
}
.asobikata p{
  font-size: 18px;
  font-weight: 900;
  color: #FFFFFF;
  padding: 4px 14px;
  margin: 0;
}
.start{
  background: #ffffff;
  border: #FD9802 solid 5px;
  border-radius: 10px;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 21;
}
.start p{
  font-size: 16px;
  font-weight: 900;
  color: #FD9802;
  padding: 10px 20px;
  margin: 0;
}



.scene1_img8{
  display: none;
  width: 2700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  opacity: 0;
}
.show .scene1_img8{
  display: block;
  position: absolute;
  opacity: 1;
  animation-name: scene1-img8; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
.show .scene1_img8_border{
  display: hidden;
  width: 677px;
  height: 375px;
  border: solid 16px #FEA200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;
  animation-name: scene1-img9; /*アニメーションの名前*/
  animation-duration: 3s; /*アニメーションにかける時間*/
  animation-delay: 0s; /*始まる時間*/
  animation-iteration-count: 1;/*アニメーション繰り返し回数*/
  animation-fill-mode: forwards;
}
@keyframes  scene1-img8 {
  0%{
    display: block;
    width: 2708px;
    height: 1500px;
  }
  40%{
    width: 677px;
    height: 375px;
  }
  46%{
    width: 677px;
    height: 375px;
  }
  52%{
    width: 1015.5px;
    height: 562.5px;
  }
  57%{
    width: 1015.5px;
    height: 562.5px;
  }
  90%{
    width: 0px;
    height: 0px;
  }
}
@keyframes  scene1-img9 {
  0%{
    display: block;
    width: 2610px;
    height: 1402px;
    border: solid 98px #FEA200;
  }
  40%{
    width: 677px;
    height: 375px;
  }
  46%{
    width: 677px;
    height: 375px;
  }
  52%{
    width: 1015.5px;
    height: 562.5px;
  }
  57%{
    width: 1015.5px;
    height: 562.5px;
  }
  86%{
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
  100%{
    width: 0px;
    height: 0px;
    border: solid 400px #FEA200;
  }
}

.asobikata_img{
  width: calc(100% - 60px);
  background: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}
</style>
